import React from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Stack, TextField, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

// utils
import axios from '../../../utils/axios';

const QuickAddCustomer = ({handleOnCancelAddCustomerModal}) => {
  const { enqueueSnackbar } = useSnackbar();

  const QuickNewCustomerSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('Fist name is required')
      .matches(/^\D*$/, 'Invalid First Name')
      .min(2, 'First Name must be at least 2 characters')
      .max(50, 'First Name must be at most 50 characters'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^(?:7|0|(?:\+94))[0-9]{9,10}$/, 'Phone number is invalid')
      .test(
        'Unique phone number',
        'Phone number already in use',
        (value) =>
          new Promise((resolve) => {
            axios.post('/business/customer/check-phone', { phone: value }).then((res) => {
              if (res.data.msg === 'Taken') {
                resolve(false);
              }
              resolve(true);
            });
          })
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      phoneNumber: '',
    },
    validationSchema: QuickNewCustomerSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await axios.post('/business/customer', {
          firstName: values.firstName,
          phone: values.phoneNumber,
        });
        console.log(values);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar('Create success!');
        handleOnCancelAddCustomerModal();
      } catch (error) {
        console.error(error);
        if (error.errors) {
          error.errors.forEach((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
          });
        }
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Typography sx={{ marginBottom: 4, fontWeight: 700 }}>Add Customer Quickly</Typography>
        <Stack spacing={3} direction="row">
          <TextField
            fullWidth
            label="First Name"
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
          <TextField
            fullWidth
            label="Phone Number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{ marginY: 4 }}
            disabled={!isValid}
          >
            Add
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  );
};

QuickAddCustomer.propTypes = {
  handleOnCancelAddCustomerModal: PropTypes.func.isRequired
}

export default QuickAddCustomer;
