import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import CategoryNewForm from '../../../sections/@dashboard/category/CategoryNewForm';
// redux
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';


// ----------------------------------------------------------------------

export default function CategoriesCreate() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const { name } = useParams();
  const isEdit = pathname.includes('edit');
  


  return (
    <Page title="Ecommerce: Create a new Category">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new Category' : 'Edit Category'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Category',
              href: PATH_DASHBOARD.categories.root,
            },
            { name: !isEdit ? 'New Category' : name },
          ]}
        />

        <CategoryNewForm isEdit={isEdit}  />
      </Container>
    </Page>
  );
}
