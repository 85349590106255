import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { constantCase } from 'change-case';
import { useSnackbar } from 'notistack';
import { useNavigate,useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
    Card,
    Grid,
    Stack,
    TextField,
    Typography,
    FormHelperText
} from '@mui/material';




import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

import { UploadMultiFile } from '../../../components/upload';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// utils
import axios from '../../../utils/axios';
import { FIREBASE_API } from '../../../config';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));
CategoryNewForm.propTypes = {
    isEdit: PropTypes.bool
};

export default function CategoryNewForm({ isEdit }) {
    const navigate = useNavigate();
    const [currentCategory,setCurrentCategory] = useState(null);
    const [imageUrls, setimageUrls] = useState([]); // THIS state will store the image URLs. If you want u can use the setFieldValue also when assigning.
    
    const { enqueueSnackbar } = useSnackbar();
    const { id = '' } = useParams();
    if (!firebase.apps.length) {
        firebase.initializeApp(FIREBASE_API);
        firebase.firestore();
        firebase.storage();
      }

      useEffect(() => {
        // dispatch(getProducts());
        async function fetchData() {
          try {
            const response = await axios.get(`business/category/${id}`);
            setCurrentCategory(response.data);
            setimageUrls(response.data.imageURLs);
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        }
        if(id !== '') {
            fetchData();
        }
        
      }, [id]);

    const NewProductSchema = Yup.object().shape({
        categoryCode: Yup.string().required('Category code is required').min(2, 'Category code must be at least 2 characters').max(20, 'Category code must be at most 20 characters').
            test('Unique category code', 'Category code already in use',
                (value) =>
                    new Promise((resolve) => {
                        axios.get(`/business/category/category-code-check/${constantCase(value)}`)
                            .then(res => { if (!res.data.available) { resolve(false) } resolve(true) })
                    })),
        categoryName: Yup.string().required('Category name is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            images: isEdit ? currentCategory?.imageURLs :  [],
            categoryCode: isEdit ? currentCategory?.categoryCode : '',
            categoryName: isEdit ? currentCategory?.name :'',
        },
        validationSchema: NewProductSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                // axios here
                await axios.post('/business/category', {
                    name: values.categoryName,
                    categoryCode: constantCase(values.categoryCode),
                    imageURLs: imageUrls,
                });
                // if (response.status === 200) {}
                await new Promise((resolve) => setTimeout(resolve, 500));
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
                navigate(PATH_DASHBOARD.categories.list);
            } catch (error) {
                console.log(error);
                if (error.errors) {
                    error.errors.forEach((err) => {
                        enqueueSnackbar(err.message, { variant: 'error' });
                    })
                }
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid,values,setFieldValue } = formik;
    const [isUploading, setUploading] = useState(false); // This state will be used to show the loading spinner.
    function uploadtoFirebase(e) {
        // Show loading spinner
        setUploading(true);
        const path = 'BusinesItemImages'; // path for file storage ie: folder name
        const newtime = new Date().getTime(); // get absolute time
        const uploadTask = firebase.storage().ref(`${path}/${newtime}`).put(e); // upload with file name as absolute time
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // for measure the progress. not required here
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            console.log(progress);
          },
          (error) => {
            console.log(error);
            alert(error.message);
          },
          () => {
            setUploading(false);
            // get the url of saved image
            firebase
              .storage()
              .ref(`${path}`)
              .child(newtime.toString())
              .getDownloadURL()
              .then((url) => {
                setimageUrls((c) => [...c, url]); // add to url list state
                return url;
              });
          }
        );
    }

    const handleDrop = useCallback(
        (acceptedFiles) => {
          setFieldValue(
            'images',
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          acceptedFiles.map((file) => {
            uploadtoFirebase(file);
            return null;
          });
        },
        [setFieldValue]
      );
    

    const handleRemove = (file) => {
        const filteredItems = values.images.filter((_file) => _file !== file);
        setFieldValue('images', filteredItems);
    };

    const handleRemoveAll = () => {
        setFieldValue('images', []);
        setimageUrls([]);
      };

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={12} md={6}>
                        <Stack spacing={3}>
                            <Card sx={{ p: 3 }}>
                                {id}

                                <Stack spacing={3}>
                                    {isEdit ? 
                                    <TextField disabled fullWidth label="Category Code" {...getFieldProps('categoryCode')}
                                    />
                                    :
                                    <TextField fullWidth label="Category Code" {...getFieldProps('categoryCode')}
                                        error={Boolean(touched.categoryCode && errors.categoryCode)}
                                        helperText={touched.categoryCode && errors.categoryCode}
                                    />
                                    }
                                    
                                    <TextField fullWidth label="Category Name" {...getFieldProps('categoryName')}
                                        error={Boolean(touched.categoryName && errors.categoryName)}
                                        helperText={touched.categoryName && errors.categoryName}
                                    />

                                    {/* category images */}
                                    <div>
                                    {imageUrls}
                                    <LabelStyle>Add Images</LabelStyle>
                                    <UploadMultiFile
                                        showPreview
                                        maxSize={3145728}
                                        accept="image/*"
                                        files={values.images}
                                        onDrop={handleDrop}
                                        onRemove={handleRemove}
                                        onRemoveAll={handleRemoveAll}
                                        error={Boolean(touched.images && errors.images)}
                                    />
                                    {touched.images && errors.images && (
                                        <FormHelperText error sx={{ px: 2 }}>
                                        {touched.images && errors.images}
                                        </FormHelperText>
                                    )}
                                    </div>
                                </Stack>
                            </Card>
                            <LoadingButton type="submit" fullWidth variant="contained" size="large"  loading={isSubmitting || isUploading} disabled={!isValid && isUploading}>
                                {!isEdit ? 'Create Product' : 'Save Changes'}
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
