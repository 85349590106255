/* eslint-disable */
import { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// import { paramCase, capitalCase } from 'change-case';
// import { useParams, useLocation } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  // Avatar,
  Button,
  // Checkbox,
  Box,
  IconButton,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  // TablePagination,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  // Switch,
  // FormHelperText,
  // FormControlLabel,
  Modal,
} from '@mui/material';
// @mui
import { LoadingButton /*  ,DesktopDatePicker,MobileDatePicker */ } from '@mui/lab';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { UserListHead, UserMoreMenu } from '../../sections/@dashboard/user/list';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
// components
import Iconify from '../../components/Iconify';
// utils
import axios from '../../utils/axios';
import QuickAddCustomer from 'src/sections/@dashboard/customer/QuickAddCustomer';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'itemName', label: 'Item Name', alignRight: false },
  { id: 'qty', label: 'QTY', alignRight: false },
  { id: 'unitPrice', label: 'Unit Price', alignRight: false },
  { id: 'total', label: 'Total', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
function PosUI() {
  const { themeStretch } = useSettings();
  const [bizItemsList, setBizItemsList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemList.length) : 0;
  const [filterName, setFilterName] = useState('');
  const [itemQty, setItemQty] = useState(0);
  const isNotFound = !itemList.length;
  const CHECK_STOCKS = true;
  const [isAddCustomerModalOpen, toggleAddCustomerModalOpen] = useState(false);
  const [customerList, setCustomerList] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/business/item/shop/');
        setBizItemsList(response.data);
        const customers = await axios.get('/business/customer/shop');

        // create array of first name of customer
        const firstNamesOfCustomers = customers.data.map((customer, index) => {
                  return {label: customer.firstName, id: index}
              })
        setCustomerList(firstNamesOfCustomers)
      } catch (error) {
        if (error.errors) {
          error.errors.forEach((err) => {
            // eslint-disable-next-line
            enqueueSnackbar(err.message, { variant: 'error' });
          });
        }
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleOnCancelAddCustomerModal = () => {
    toggleAddCustomerModalOpen(!isAddCustomerModalOpen);
  };

  const NewUserSchema = Yup.object().shape({
    customer: Yup.string(),
    item: Yup.object()
      .shape({
        name: Yup.string().required('Item name is required'),
      })
      .required('this field is required')
      .test(
        'Check stocks',
        'Check stocks',
        (value) =>
          new Promise((resolve) => {
            if (CHECK_STOCKS) {
              axios.get(`/stocks/stockItemCount/stock-item-count-by-id/${value.id}`).then((res) => {
                setItemQty(res.data.stockItemCount);
                resolve(true);
              });
            } else {
              resolve(true);
            }
          })
      ),
    quantity: Yup.number()
      .required('Quantity is required')
      .min(1, 'Quantity must be at least 1')
      .test(
        'qty',
        `Available quantity is ${itemQty}`,
        (value) =>
          new Promise((resolve) => {
            if (!CHECK_STOCKS) {
              resolve(true);
            } else {
              if (value <= itemQty) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          })
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item: null,
      customer: '',
      quantity: '',
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const newData = {
          id: itemList.length + 1,
          itemName: values.item.name,
          itemId: values.item.id,
          quantity: values.quantity,
          unitPrice: values.item.price, //TODO remove hardcode variable
          totalPrice: values.item.price * values.quantity,
        };
        setItemList((oldArray) => [...oldArray, newData]);
        document.getElementById('outlined-basic').focus();

        resetForm();
        setSubmitting(false);
        enqueueSnackbar('Update success!');
        // navigate(PATH_DASHBOARD.customers.root);
      } catch (error) {
        console.error(error);
        if (error.errors) {
          error.errors.forEach((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
          });
        }
        setSubmitting(false);
        setErrors(error);
      }
    },
  });
  const handleDeleteItem = (id) => {
    const deleteItem = itemList.filter((item) => item.id !== id);
    setItemList([]);
    setItemList(deleteItem);
  };
  const saveSale = async () => {
    if (itemList.length === 0) {
      enqueueSnackbar('Add items to finish', { variant: 'error' });
    } else {
      //POST data to cloud
      try {
        const saleResponse = await axios.post('/business/new-sale', {
          data: {
            type: 'cash',
            customer: false,
          },
          items: itemList,
        });
        enqueueSnackbar('Sale success!', { variant: 'success' });
        console.log(saleResponse.data.newSale);
        //TODO send receipt to print
        setItemList([]);
      } catch (error) {
        if (error.errors) {
          error.errors.forEach((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
          });
        }
      }
    }
  };
  const cancel = () => {
    setItemList([]);
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid, setFieldValue, values } = formik;

  return (
    <Page title="User: Create a new user">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <UserNewForm isEdit={isEdit} currentUser={currentUser} /> */}
        <Button variant="contained" sx={{ m: 3 }} onClick={handleOnCancelAddCustomerModal}>
          Add Customer
        </Button>

        <Modal
        open={isAddCustomerModalOpen}
        onClose={handleOnCancelAddCustomerModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
            display: 'flex',
            justifyContent: 'center',
            height: "fit-content",
            marginTop: 'auto',
            marginBottom: 'auto'
        }}

      >
        <Box sx={{ width: 'fit-content', backgroundColor: 'white', p: 6 }}>
          <QuickAddCustomer handleOnCancelAddCustomerModal={handleOnCancelAddCustomerModal} />
        </Box>
      </Modal>

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                      {/* customer */}
                      <Autocomplete
                                                value={values.customer}
                                                onChange={(event, value,reason) => {
                                                        //TODO console.log(`event ${JSON.stringify(event)}`)
                                                        setFieldValue('customer', value ? value : null);
                                                        // focus next input field
                                                        document.getElementById('outlined-basic').focus();
                                                    } 
                                                }
                                                id="customer-select"
                                                options={customerList}
                                                getOptionLabel={(option) => option?option.label:''}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.id}>
                                                            {option.label}
                                                        </li>
                                                    )
                                                }}
                                                renderInput={(params) => 
                                                    <TextField 
                                                        {...params} 
                                                        onChange={(event) => {
                                                            console.log(`customer: ${event.target.value}`);
                                                        } }
                                                        id="customer"  
                                                        label="Customer" 
                                                        variant="outlined" 
                                                        autoFocus
                                                        
                                                    />
                                                }
                                                clearIcon={false}
                                                isOptionEqualToValue={(option, value) => option.label === value}
                                            />
                      {/* Items */}
                      <Autocomplete
                        value={values.item}
                        onChange={(event, value, reason) => {
                          //TODO console.log(`event ${JSON.stringify(event)}`)
                          setFieldValue('item', value ? value : null);
                          // focus next input field
                          document.getElementById('quantity').focus();
                        }}
                        id="outlined-basic"
                        options={bizItemsList}
                        getOptionLabel={(option) => (option.name ? option.name : '')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onChange={(event) => {
                              console.log(`value: ${event.target.value}`);
                            }}
                            id="outlined-basic"
                            label="Select Item"
                            variant="outlined"
                            autoFocus
                          />
                        )}
                      />
                      <TextField
                        id="quantity"
                        fullWidth
                        label="Quantity"
                        {...getFieldProps('quantity')}
                        error={Boolean(touched.quantity && errors.quantity)}
                        helperText={touched.quantity && errors.quantity}
                      />
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!isValid}>
                        Add Item
                      </LoadingButton>
                      <Button variant="contained" onClick={() => saveSale()}>
                        Finish
                      </Button>
                      <Button variant="contained" onClick={() => cancel()}>
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </FormikProvider>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    headLabel={TABLE_HEAD}
                    rowCount={itemList.length}
                    // numSelected={selected.length}
                    // onRequestSort={handleRequestSort}
                    // onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {itemList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { id, itemName, quantity, unitPrice, totalPrice } = row;
                      // const isItemSelected = selected.indexOf(id) !== -1;
                      itemName;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          // role="checkbox"
                          // selected={isItemSelected}
                          // aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* //TODO 
                                                        <Checkbox checked={isItemSelected} onClick={() => handleClick(username)} /> */}
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </TableCell>
                          <TableCell>{itemName}</TableCell>
                          <TableCell align="left">{quantity}</TableCell>
                          <TableCell align="left">{unitPrice}</TableCell>
                          <TableCell align="left">{totalPrice}</TableCell>

                          <TableCell align="right">
                            <IconButton onClick={() => handleDeleteItem(id)} color="primary" aria-label="Delete Item">
                              <Iconify icon={'eva:trash-2-outline'} sx={{ width: 24, height: 24 }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default PosUI;
