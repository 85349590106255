/* eslint-disable */
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';


import { 
    Card,
    Table,
    IconButton,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    Grid,
    Stack,
    TextField,
    Autocomplete,
 } from '@mui/material';

// @mui
import { LoadingButton } from '@mui/lab';

import { UserListHead } from '../../../sections/@dashboard/user/list';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
// components
import Iconify from '../../../components/Iconify';
// utils
import axios from '../../../utils/axios';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'supplier', label: 'Supplier', alignRight: false },
  { id: 'item', label: 'Item', alignRight: false },
  { id: 'quantity', label: 'QYT', alignRight: false },
  { id: 'purchPrice', label: 'Purchase Price', alignRight: false },
  { id: 'cashPrice', label: 'Cash Price', alignRight: false },
  { id: '' },
];

export default function AddInventory() {
    const [suppliersList,setSuppliersList] = useState([]);
    const [itemsList,setItemsList] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemsList.length) : 0;
    const [filterName, setFilterName] = useState('');
    // const [itemQty,setItemQty] = useState(0);
    const isNotFound = !itemsList.length;


    useEffect(() => {
        async function fetchData() {
            try {
              const response = await axios.get('/business/suppliers/biz');
              setSuppliersList(response.data);
            } catch (error) {
              if (error.errors) {
                error.errors.forEach((err) => {
                  // eslint-disable-next-line 
                  enqueueSnackbar(err.message, { variant: 'error' });
                })
              }
            }
          }
          fetchData();
          // eslint-disable-next-line 
    },[])

    useEffect(() => {
        async function fetchData() {
            try {
              const response = await axios.get('/business/item/shop/');
              setItemsList(response.data);
            } catch (error) {
              if (error.errors) {
                error.errors.forEach((err) => {
                  // eslint-disable-next-line 
                  enqueueSnackbar(err.message, { variant: 'error' });
                })
              }
            }
          }
          fetchData();
          // eslint-disable-next-line 
    },[])


    const NewUserSchema = Yup.object().shape({
        supplier: Yup.string().required('Supplier is required'),
        item: Yup.string().required('Item is required'),
        quantity: Yup.number().required('Quantity is required').min(1, 'Quantity must be at least 1'),
        purchasePrice: Yup.number().required('Purchase price is required').positive('Purchase price must be positive'),
        cashPrice: Yup.number().required('Cash price is required').positive('Cash price must be positive'),
      });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            supplier: '',
            item: '',
            quantity: '',
            purchasePrice:  '',
            cashPrice:  '',
            // status: '',
            // role:  '',
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                await axios.post('/business/customer', {
                    supplier: values.supplier,
                    item: values.item,
                    quantity: values.quantity,
                    purchasePrice: values.purchasePrice,
                    cashPrice: values.cashPrice,
                });
                resetForm();
                setSubmitting(false);
                enqueueSnackbar('Update success!');
                // navigate(PATH_DASHBOARD.customers.root);
            } catch (error) {
                console.error(error);
                if (error.errors) {
                    error.errors.forEach((err) => {
                        enqueueSnackbar(err.message, { variant: 'error' });
                    })
                }
                setSubmitting(false);
                setErrors(error);
            }
        },
    });
    const handleDeleteItem = (id) => {
      const deleteItem = itemsList.filter((item) => item.id !== id);
      setItemsList([]);
      setItemsList(deleteItem);
    };

    // const saveSale = async () => {
    //   if(itemList.length === 0){
    //       enqueueSnackbar("Add items to finish", { variant: 'error' });
    //   }
    //   else{
    //       //POST data to cloud
    //       try{
    //           const saleResponse = await axios.post('/business/new-sale', {
    //               data:{
    //                   "type":"cash",
    //                   "customer":false
    //                     },
    //               items:itemList
    //           });
    //           enqueueSnackbar('Sale success!', { variant: 'success' });
    //           console.log(saleResponse.data.newSale);
    //           //TODO send receipt to print
    //           setItemList([]);
    //       }
    //       catch(error){
    //           if (error.errors) {
    //               error.errors.forEach((err) => {
    //                   enqueueSnackbar(err.message, { variant: 'error' });
    //               })
    //           }
    //       }
    //   }
    // }
    // const cancel = () => {
    //     setItemList([]);
    // }


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid,setFieldValue } = formik;
    return (

        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
            <Card sx={{ p: 3 }}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Autocomplete
                    onChange={(event, value,reason) => {
                      if(reason === 'selectOption'){
                        setFieldValue('supplier', value.id);
                      }else{
                        setFieldValue('supplier', '');
                      }
                    } }
                                id="outlined-basic"
                                options={suppliersList}
                                getOptionLabel={(option) => option.firstName}
                                renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    id="outlined-basic"  
                                    label="Select Supplier" 
                                    variant="outlined" 
                                    {...getFieldProps('supplier')}
                                    error={Boolean(touched.supplier && errors.supplier)}
                                    helperText={touched.supplier && errors.supplier}
                                    />}
                                />
                {/* Items */}
                <Autocomplete
                    onChange={(event, value,reason) => {
                      if(reason === 'selectOption'){
                        setFieldValue('item', value.id);
                      }else{
                        setFieldValue('item', '');
                      }
                    } }
                                id="outlined-basic"
                                options={itemsList}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => 
                                  <TextField 
                                    {...params} 
                                    id="outlined-basic"  
                                    label="Select Item" 
                                    variant="outlined" 
                                    {...getFieldProps('item')}
                                    error={Boolean(touched.item && errors.item)}
                                    helperText={touched.item && errors.item}
                                />}
                                />

                <TextField 
                  id="outlined-basic" 
                  label="Quantity" 
                  variant="outlined" 
                  {...getFieldProps('quantity')}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />
                <TextField 
                  id="outlined-basic" 
                  label="Purchase Price" 
                  variant="outlined" 
                  {...getFieldProps('purchasePrice')}
                  error={Boolean(touched.purchasePrice && errors.purchasePrice)}
                  helperText={touched.purchasePrice && errors.purchasePrice}
                />
                <TextField 
                  id="outlined-basic" 
                  label="Cash Price" 
                  variant="outlined" 
                  {...getFieldProps('cashPrice')}
                  error={Boolean(touched.cashPrice && errors.cashPrice)}
                  helperText={touched.cashPrice && errors.cashPrice}
                />
                <LoadingButton type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}  disabled={!isValid}>
                    add item 
                </LoadingButton>

                </Stack>
                </Form >
            </FormikProvider >
            </Card>
            </Grid>
            <Grid item xs={12} md={8}>
            
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <UserListHead
                            order={order}
                            headLabel={TABLE_HEAD}
                            rowCount={itemsList.length}
                        // numSelected={selected.length}
                        // onRequestSort={handleRequestSort}
                        // onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                            {itemsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                const { id, supplier, item, quantity, purchasePrice,cashPrice } = row;
                                // const isItemSelected = selected.indexOf(id) !== -1;

                                return (
                                    <TableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        // role="checkbox"
                                    // selected={isItemSelected}
                                    // aria-checked={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            {/* //TODO 
                                            <Checkbox checked={isItemSelected} onClick={() => handleClick(username)} /> */}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography variant="subtitle2" noWrap>
                                                {id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {supplier}
                                        </TableCell>
                                        <TableCell align="left">{item}</TableCell>
                                        <TableCell align="left">{quantity}</TableCell>
                                        <TableCell align="left">
                                            {purchasePrice}
                                        </TableCell>
                                        <TableCell align="left">
                                            {cashPrice}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDeleteItem(id)} color="primary" aria-label="Delete Item">
                                                <Iconify icon={'eva:trash-2-outline'} sx={{ width: 24, height: 24 }}/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        {isNotFound && (
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                        <SearchNotFound searchQuery={filterName} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
              </Scrollbar>
            </Grid>
        </Grid>
        
            

    )
}

