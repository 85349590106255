// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  supplier: getIcon('ic_supplier'),
  inventory: getIcon('ic_inventory'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      { title: 'POS', path: PATH_DASHBOARD.general.pos, icon: ICONS.ecommerce },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      {
        title: 'customer',
        path: PATH_DASHBOARD.general.booking,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.customers.list },
          { title: 'create', path: PATH_DASHBOARD.customers.new },
          { title: 'edit', path: PATH_DASHBOARD.customers.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },
      {
        title: 'inventory',
        path: PATH_DASHBOARD.general.booking,
        icon: ICONS.inventory,
        children: [
          { title: 'list', path: PATH_DASHBOARD.inventory.list },
          { title: 'create', path: PATH_DASHBOARD.inventory.new },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'Employee',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.newUser },
          { title: 'edit', path: PATH_DASHBOARD.user.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },
      // MANAGEMENT : SUPPLIERS
      {
        title: 'Supplier',
        path: PATH_DASHBOARD.suppliers.root,
        icon: ICONS.supplier,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.suppliers.list },
          { title: 'create', path: PATH_DASHBOARD.suppliers.new },
          { title: 'edit', path: PATH_DASHBOARD.suppliers.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },
  // CONFIGURATION
  // ----------------------------------------------------------------------
  {
    subheader: 'Configuration',
    items: [
      // CONFIGURATION : PRODCUTS / CATEGORIES
      {
        title: 'Categories',
        path: PATH_DASHBOARD.categories.root,
        icon: ICONS.blog,
        children: [
          { title: 'List', path: PATH_DASHBOARD.categories.list },
          { title: 'Create', path: PATH_DASHBOARD.categories.newCategory },
          // { title: 'Edit', path: PATH_DASHBOARD.categories.editById },
        ],
      },
      // CONFIGURATION : PRODUCTS / ITEMS
      {
        title: 'Products',
        path: PATH_DASHBOARD.products.root,
        icon: ICONS.cart,
        children: [
          { title: 'List', path: PATH_DASHBOARD.products.list },
          { title: 'Create', path: PATH_DASHBOARD.products.newProduct },
          // { title: 'Edit', path: PATH_DASHBOARD.products.editById },
        ],
      },
    ],
  },
  {
    subheader: "Price History",
    items: [
      {
        title: "organization",
        path: PATH_DASHBOARD.organization.root,
        icon: ICONS.banking,
        children: [
          {title: 'Add organization', path: PATH_DASHBOARD.organization.new}
        ]
      }
    ]
  },
];

export default navConfig;
