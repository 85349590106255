import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// @mui
import { LoadingButton/*  ,DesktopDatePicker,MobileDatePicker */ } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// ----------------------------------------------------------------------
SupplierNewForm.propTypes = {
    isEdit: PropTypes.bool,
    currentUser: PropTypes.object,
};


function SupplierNewForm({ isEdit, currentUser }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const NewUserSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: currentUser?.firstName || '',
            lastName: currentUser?.lastName || '',
            email: currentUser?.email || '',
            phoneNumber: currentUser?.phoneNumber || '',
            address: currentUser?.address || '',
            status: currentUser?.status,
            role: currentUser?.role || '',
        },
        validationSchema: NewUserSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                await axios.post('/business/suppliers', {
                    firstname: values.firstName,
                    lastname: values.lastName,
                    email: values.email,
                    phone: values.phoneNumber,
                    bizid: "helloo",
                    address: values.address,
                });
                resetForm();
                setSubmitting(false);
                enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
                navigate(PATH_DASHBOARD.customers.root);
            } catch (error) {
                console.error(error);
                if (error.errors) {
                    error.errors.forEach((err) => {
                        enqueueSnackbar(err.message, { variant: 'error' });
                    })
                }
                setSubmitting(false);
                setErrors(error);
            }
        },
    });

    // const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="First Name"
                                        {...getFieldProps('firstName')}
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Last Name  (Optional)"
                                        {...getFieldProps('lastName')}
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />

                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        {...getFieldProps('phoneNumber')}
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email Address  (Optional)"
                                        {...getFieldProps('email')}
                                    />


                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                    <TextField
                                        fullWidth
                                        label="Address (Optional)"
                                        {...getFieldProps('address')}
                                    />
                                </Stack>
                                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                        {!isEdit ? 'Create Supplier' : 'Save Changes'}
                                    </LoadingButton>
                                </Box>
                            </Stack>
                        </Card>
                    </Grid>
                </Grid>
            </Form >
        </FormikProvider >
    );
}

export default SupplierNewForm;
