import React from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// @mui
import {
  Container,
  Stack,
  Alert,
  TextField,
  Card,
  Grid,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/Breadcrumbs';
import Page from '../../../components/Page';

// ----------------------------------------------------------------------
const AddOrganization = () => {
  const { themeStretch } = useSettings();

  const OrganizationSchema = Yup.object().shape({
    organization: Yup.string().required('Organization name is required'),
    province: Yup.string().required('Province is required'),
    district: Yup.string().required('District is required'),
    longitude: Yup.string(),
    latitude: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      organization: '',
      province: '',
      district: '',
      longitude: '',
      latitude: '',
    },
    validationSchema: OrganizationSchema,
    onSubmit: async (values) => {
      console.log(values)
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Organization: Create a new Organization">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Typography variant='h4'>
          Create a new Organization
        </Typography>
        <HeaderBreadcrumbs
          heading='Create a new Organization'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Organization', href: '' },
            { name: 'New Organization' },
          ]}
        />
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item xs={12}>
              {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
              <Card sx={{ p: 3 }}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} marginY={2}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Organization name"
                    {...getFieldProps('organization')}
                    error={Boolean(touched.organization && errors.organization)}
                    helperText={touched.organization && errors.organization}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} marginBottom={2}>
                  <TextField
                    fullWidth
                    autoComplete="province"
                    type="text"
                    label="province"
                    {...getFieldProps('province')}
                    error={Boolean(touched.province && errors.province)}
                    helperText={touched.province && errors.province}
                  />
                  <TextField
                    fullWidth
                    autoComplete="district"
                    type="email"
                    label="District"
                    {...getFieldProps('district')}
                    error={Boolean(touched.district && errors.district)}
                    helperText={touched.district && errors.district}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} marginBottom={2}>
                  <TextField
                    fullWidth
                    autoComplete="longitude"
                    type="text"
                    label="Longitude"
                    {...getFieldProps('longitude')}
                    error={Boolean(touched.longitude && errors.longitude)}
                    helperText={touched.longitude && errors.longitude}
                  />
                  <TextField
                    fullWidth
                    autoComplete="latitude"
                    type="text"
                    label="Latitude"
                    {...getFieldProps('latitude')}
                    error={Boolean(touched.latitude && errors.latitude)}
                    helperText={touched.latitude && errors.latitude}
                  />
                </Stack>

                <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting}>
                  Add
                </LoadingButton>
              </Card>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
};

export default AddOrganization;
