import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// routes
//
import Editor from '../../../components/editor';
import { UploadMultiFile } from '../../../components/upload';
import { FIREBASE_API } from '../../../config';
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import axios from '../../../utils/axios';
// ----------------------------------------------------------------------

// const GENDER_OPTION = ['Men', 'Women', 'Kids'];

// const CATEGORY_OPTION = [
//   { group: 'Clothing', classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather'] },
//   { group: 'Tailored', classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats'] },
//   { group: 'Accessories', classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'] },
// ];

// const TAGS_OPTION = [
//   'Toy Story 3',
//   'Logan',
//   'Full Metal Jacket',
//   'Dangal',
//   'The Sting',
//   '2001: A Space Odyssey',
//   "Singin' in the Rain",
//   'Toy Story',
//   'Bicycle Thieves',
//   'The Kid',
//   'Inglourious Basterds',
//   'Snatch',
//   '3 Idiots',
// ];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

ProductNewForm.propTypes = {
  isEdit: PropTypes.bool,
};

export default function ProductNewForm({ isEdit }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [categoryList, setCategoryList] = useState([]);

  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
    firebase.firestore();
    firebase.storage();
  }



  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    category: Yup.string().required('Category is required'),
    barcode: Yup.string().required('Barcode is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name:  '',
      description:  '',
      images:  [],
      barcode:  '',
      sku: '',
      price:  '',
      priceSale:  '',
      tags: [],
      useManuBarCode: false,
      ihubPublic: true,
      taxes: true,
      category: '',
      material:''
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        await axios.post('/business/item/', {
          name: values.name,
          category: values.category,
          useManuBarCode: values.useManuBarCode,
          barcode: values.barcode,
          url:imageUrls,
          description:values.description,
          material:values.material,
        });
        resetForm();
        setSubmitting(false);
        enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
        navigate(PATH_DASHBOARD.products.root);
      } catch (error) {
        console.error(error);
        if (error.errors) {
          error.errors.forEach((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
          })
        }
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, isValid } = formik;

  const [imageUrls, setimageUrls] = useState([]); // THIS state will store the image URLs. If you want u can use the setFieldValue also when assigning.
  const [isUploading, setUploading] = useState(false); // This state will be used to show the loading spinner.

  function uploadtoFirebase(e) {
    // Show loading spinner
    setUploading(true);
    const path = 'BusinesItemImages'; // path for file storage ie: folder name
    const newtime = new Date().getTime(); // get absolute time
    const uploadTask = firebase.storage().ref(`${path}/${newtime}`).put(e); // upload with file name as absolute time
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // for measure the progress. not required here
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log(progress);
      },
      (error) => {
        console.log(error);
        alert(error.message);
      },
      () => {
        setUploading(false);
        // get the url of saved image
        firebase
          .storage()
          .ref(`${path}`)
          .child(newtime.toString())
          .getDownloadURL()
          .then((url) => {
            setimageUrls((c) => [...c, url]); // add to url list state
            return url;
          });
      }
    );
  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFieldValue(
        'images',
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      acceptedFiles.map((file) => {
        uploadtoFirebase(file);
        return null;
      });
    },
    [setFieldValue]
  );

  const handleRemoveAll = () => {
    setFieldValue('images', []);
    setimageUrls([]);
  };

  const handleRemove = (file) => {
    const filteredItems = values.images.filter((_file) => _file !== file);
    setFieldValue('images', filteredItems);
  };

  // get categories options
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/business/category/shop');
        setCategoryList(response.data);
      } catch (error) {
        if (error.errors) {
          error.errors.forEach((err) => {
            // eslint-disable-next-line 
            enqueueSnackbar(err.message, { variant: 'error' });
          })
        }
      }
    }
    fetchData();
    // eslint-disable-next-line 
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Product Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select label="Category" native {...getFieldProps('category')} value={values.category} error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}>
                    <option value="">SELECT</option>
                    {categoryList.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                {/* Use ma nufactures bar code */}
                <FormControlLabel
                  control={<Switch {...getFieldProps('useManuBarCode')} checked={values.useManuBarCode} />}
                  label="Use Manufactures Bar Code"
                  sx={{ mb: 2 }}
                />
                <TextField fullWidth label="Product Code" {...getFieldProps('barcode')} error={Boolean(touched.barcode && errors.barcode)}
                  helperText={touched.barcode && errors.barcode} />
                {/* Show item in ihub */}
                <FormControlLabel
                  control={<Switch {...getFieldProps('ihubPublic')} checked={values.ihubPublic} />}
                  label="Show Item In Ihub"
                  sx={{ mb: 2 }}
                />

                {/* Show item in ihub */}
                {(values.ihubPublic) && (
                  <>
                    <div>
                      <LabelStyle>Description</LabelStyle>
                      <Editor
                        simple
                        id="product-description"
                        value={values.description}
                        onChange={(val) => setFieldValue('description', val)}
                        error={Boolean(touched.description && errors.description)}
                      />
                      {touched.description && errors.description && (
                        <FormHelperText error sx={{ px: 2 }}>
                          {touched.description && errors.description}
                        </FormHelperText>
                      )}
                    </div>
                    <div>
                      <TextField
                      fullWidth
                      label="Material"
                      {...getFieldProps('material')}
                      error={Boolean(touched.material && errors.material)}
                      helperText={touched.material && errors.material}
                      />
                    </div>
                    <div>
                      <LabelStyle>Add Images</LabelStyle>
                      <UploadMultiFile
                        showPreview
                        maxSize={3145728}
                        accept="image/*"
                        files={values.images}
                        onDrop={handleDrop}
                        onRemove={handleRemove}
                        onRemoveAll={handleRemoveAll}
                        error={Boolean(touched.images && errors.images)}
                      />
                      {touched.images && errors.images && (
                        <FormHelperText error sx={{ px: 2 }}>
                          {touched.images && errors.images}
                        </FormHelperText>
                      )}
                    </div>
                    {/* <Autocomplete
                      multiple
                      freeSolo
                      value={values.tags}
                      onChange={(event, newValue) => {
                        setFieldValue('tags', newValue);
                      }}
                      options={TAGS_OPTION.map((option) => option)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                        ))
                      }
                      renderInput={(params) => <TextField label="Tags" {...params} />}
                    /> */}

                  </>
                )}


                <LoadingButton type="submit" fullWidth variant="contained" size="large" loading={isSubmitting || isUploading} disabled={!isValid && isUploading}>
                  {!isEdit ? 'Create Product' : 'Save Changes'}
                </LoadingButton>

              </Stack>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>


                <Stack spacing={3}>

                  <TextField fullWidth label="Product SKU" {...getFieldProps('sku')} />

                  <div>
                    <LabelStyle>Gender</LabelStyle>
                    <RadioGroup {...getFieldProps('gender')} row>
                      <Stack spacing={1} direction="row">
                        {GENDER_OPTION.map((gender) => (
                          <FormControlLabel key={gender} value={gender} control={<Radio />} label={gender} />
                        ))}
                      </Stack>
                    </RadioGroup>
                  </div>


                  <Autocomplete
                    multiple
                    freeSolo
                    value={values.tags}
                    onChange={(event, newValue) => {
                      setFieldValue('tags', newValue);
                    }}
                    options={TAGS_OPTION.map((option) => option)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField label="Tags" {...params} />}
                  />
                </Stack>
              </Card>

              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    placeholder="0.00"
                    label="Regular Price"
                    {...getFieldProps('price')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      type: 'number',
                    }}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                  />

                  <TextField
                    fullWidth
                    placeholder="0.00"
                    label="Sale Price"
                    {...getFieldProps('priceSale')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      type: 'number',
                    }}
                  />
                </Stack>

                <FormControlLabel
                  control={<Switch {...getFieldProps('taxes')} checked={values.taxes} />}
                  label="Price includes taxes"
                  sx={{ mt: 2 }}
                />
              </Card> 


            </Stack>
          </Grid> */}
        </Grid>
      </Form>
    </FormikProvider>
  );
}
