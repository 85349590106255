import { paramCase, capitalCase } from 'change-case';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// _mock_
import { _userList } from '../../../_mock';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import CustomerNewForm from '../../../sections/@dashboard/customer/CustomerNewForm';

// ----------------------------------------------------------------------

export default function CustomerCreate() {
    const { themeStretch } = useSettings();
    const { pathname } = useLocation();
    const { name = '' } = useParams();
    const isEdit = pathname.includes('edit');

    const currentUser = _userList.find((user) => paramCase(user.name) === name);

    return (
        <Page title="User: Create a new user">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={!isEdit ? 'Create a new Customer' : 'Edit Customer'}
                    links={[
                        { name: 'Dashboard', href: PATH_DASHBOARD.root },
                        { name: 'Customer', href: PATH_DASHBOARD.customers.root },
                        { name: !isEdit ? 'New Customer' : capitalCase(name) },
                    ]}
                />

                <CustomerNewForm isEdit={isEdit} currentUser={currentUser} />
            </Container>
        </Page>
    );
}
